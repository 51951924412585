import React, { useCallback, useState } from "react";
import { DefaultTable, IconName } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { useInstallationsSearch } from "@utils/useInstallationsSearch";
import { getInstallationsRows, getInstallationsTableColumns } from "@utils/installationsTableHelpers";
import { SearchWrapper } from "@components/searchHelpers";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE } from "@utils/utils";
import { SupportDataModal } from "./supportDataModal";
import { InstallationDataModal } from "./installationDataModal";
import { useSelector } from "react-redux";
import { RootState } from "@store/rootReducer";

export const InstallationsSearch = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [searchValue, setSearchValue] = useState("");
  const [isSupportDataModalVisible, setIsSupportDataModalVisible] = useState(false);
  const [isInstallationDataModalVisible, setIsInstallationDataModalVisible] = useState(false);
  const [installationId, setInstallationId] = useState<number>(0);

  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const { installations, refetchInstallations, fetchLoading } = useInstallationsSearch();

  const debouncer = useCallback(
    debounce((newValue: string) => {
      refetchInstallations({ searchValue: newValue });
      setIsDebouncing(false);
    }, DEFAULT_DEBOUNCE),
    [refetchInstallations],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    setIsDebouncing(true);
    debouncer(newValue);
  };

  const onCloseSupportDataModal = () => {
    setIsSupportDataModalVisible(false);
  };
  const onCloseInstallationDataModal = () => {
    setIsInstallationDataModalVisible(false);
  };

  const onClickInstallationData = (installationId: number) => {
    setInstallationId(installationId);
    setIsInstallationDataModalVisible(true);
  };

  const onClickSupportData = (installationId: number) => {
    setInstallationId(installationId);
    setIsSupportDataModalVisible(true);
  };

  const installationSTableColumns = getInstallationsTableColumns(onClickInstallationData, onClickSupportData);

  return (
    <SearchWrapper iconName={IconName.HOUSE} title={i18n.t(translation.search.installations)} onChange={onChange} searchValue={searchValue}>
      {searchValue && !fetchLoading && !isDebouncing && (
        <DefaultTable columns={installationSTableColumns} rows={getInstallationsRows(installations, installationSTableColumns)} className="results-table" />
      )}
      <SupportDataModal user={user} isModalVisible={isSupportDataModalVisible} onCloseModal={onCloseSupportDataModal} installationId={installationId} />
      <InstallationDataModal isModalVisible={isInstallationDataModalVisible} onCloseModal={onCloseInstallationDataModal} installationId={installationId} />
    </SearchWrapper>
  );
};
