import { translation } from "@lang/translation";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDropdown, ComponentType, FilledButton, Variant, Icon, IconTypes, Sizes } from "@technis/ui";
import { WidgetJsonEditor } from "./WidgetJsonEditor";
import classNames from "classnames";
import { AnyObject } from "yup/lib/types";
import { DashboardConfigurationJSON } from "@technis/shared";
import { AuthService } from "@services/authService";
import { UserService } from "@services/userService";
import { DashboardSelector } from "./DashboardSelector";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import { v4 as uuidv4 } from "uuid";
import { validateConfig } from "./helpers";
import { DashboardService } from "@services/dashboardService";
import { displayToast } from "@redux/toast/toast.slice";
import { useDispatch } from "react-redux";
import { Lightbeam } from "./Lightbeam";
import { cloneDeep } from "lodash";
import { PreviewPageSwitcher } from "./PreviewPageSwitcher";
import cleanDeep from "clean-deep";
import { useNavigate } from "react-router-dom";

const DEFAULT_CONFIG = {
  dashboard: {
    name: "Test dashboard",
    organizationId: -1,
    userEmail: "",
    pages: [
      {
        name: "Page",
        icon: "",
        globalFilters: [],
        widgets: [],
      },
    ],
  },
};

const STORAGE_KEY = "widgetBuilder";

const getCachedData = () => {
  const cache = sessionStorage.getItem(STORAGE_KEY);

  if (!cache) {
    return DEFAULT_CONFIG;
  }

  try {
    return JSON.parse(cache);
  } catch {
    return DEFAULT_CONFIG;
  }
};

const setCachedData = (dashboard: Partial<DashboardConfigurationJSON>) => {
  sessionStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...dashboard,
    }),
  );
};

const getWidgetBuilderUrl = () => {
  const url = new URL(process.env.DASHBOARD_VISION_URL);
  url.pathname = "widget-builder";

  return url.href;
};

export const WidgetBuilder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [config, setWidgetConfig] = useState<Partial<DashboardConfigurationJSON>>(getCachedData());
  const configRef = useRef<Partial<DashboardConfigurationJSON>>(config);
  const [hasInitSent, setHasInitSent] = useState<Partial<boolean>>(false);
  const [, forceRender] = useState<Partial<number>>(Math.random());
  const [shouldShowPreview, setShouldShowPreview] = useState(false);
  const [isConfigValid, setConfigIsValid] = useState(false);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [showEditorLightbeam, setShowEditorLightbeam] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [sizes, setSizes] = useState<(number | string)[]>(["50%", "50%"]);

  const [areSaveOptionsVisible, setAreSaveOptionsVisible] = useState(false);
  const [showPreviewUpdateIndicator, setShowPreviewUpdateIndicator] = useState(false);

  const dispatch = useDispatch();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const widgetBuilderUrl = useMemo(getWidgetBuilderUrl, []);
  const hidePreviewUpdateIndicator = () => setShowPreviewUpdateIndicator(false);

  const getAuthToken = async (dashboardUserEmail: string | undefined) => {
    if (!dashboardUserEmail) return;

    const targetEmail = dashboardUserEmail.toLowerCase().trim();
    const users = await UserService.search(targetEmail);
    const user = users.find(({ email }) => email === targetEmail);
    if (!user) {
      return;
    }

    const {
      data: { getViewAsUserToken },
    } = await AuthService.getViewAsUserToken(user.id);

    return getViewAsUserToken;
  };

  useEffect(() => {
    setConfigIsValid(validateConfig(config));
    setCachedData(config);
    configRef.current = config;
    forceRender(Math.random());
  }, [config]);

  useEffect(() => {
    handleOnRun();
  }, [activePageIndex]);

  const onChange = useCallback(
    (value: AnyObject, updatePreview: boolean) => {
      // setWidgetConfig(value);
      // silent save to ref
      configRef.current = value;
      forceRender(Math.random());

      if (updatePreview) {
        handleOnRun(value);
      }
    },
    [setWidgetConfig],
  );

  const sendData = (data: AnyObject) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage({ widgetBuilder: { ...data } }, process.env.DASHBOARD_VISION_URL);
    }
  };

  const handleOnRun = async (directConfig?: Partial<DashboardConfigurationJSON>) => {
    const userEmail = (directConfig || configRef.current).dashboard?.userEmail;
    if (!userEmail) return;
    const token = await getAuthToken(userEmail);

    if (!token) {
      alert(`Failed to get auth token for ${userEmail}. Verify that the user exists.`);
      return;
    }

    setShouldShowPreview(true);
    setCachedData(directConfig || configRef.current);

    const configWithActivePageOnly = cloneDeep(directConfig || configRef.current);
    if (configWithActivePageOnly.dashboard) {
      configWithActivePageOnly.dashboard.pages = [configWithActivePageOnly.dashboard?.pages[activePageIndex] ?? configWithActivePageOnly.dashboard?.pages[0]];
    }

    sendData({ config: configWithActivePageOnly, token });
    setShowPreviewUpdateIndicator(true);
  };

  const onPageLoaded = async () => {
    // keep possibility to send data along with init action
    sendData({
      init: true,
    });
    if (config.dashboard?.userEmail) {
      await handleOnRun();
    }
    setHasInitSent(true);
  };

  const onDashboardSelected = async (dashboard: Partial<DashboardConfigurationJSON>) => {
    setActivePageIndex(0);
    setWidgetConfig(dashboard);
    handleOnRun(dashboard);
  };

  const hideEditorLightbeam = () => setShowEditorLightbeam(false);

  const onPublish = async (directConfig?: Partial<DashboardConfigurationJSON>) => {
    if (!isConfigValid || isPublishing) return;

    const currentConfig = directConfig || configRef.current;
    if (!currentConfig.dashboard?.organizationId || currentConfig.dashboard?.organizationId < 1) {
      // eslint-disable-next-line quotes
      alert('Missing "dashboard.organizationId" in configuration.');
      return;
    }

    setIsPublishing(true);

    try {
      const { data } = await DashboardService.createOrUpdateDashboard(currentConfig);
      const pageId = data?.createOrUpdateDashboardConfiguration;
      if (!pageId) {
        throw new Error("ID of the dashboard missing from respones");
      }

      const newConfig = await DashboardService.getDashboard(pageId);
      setWidgetConfig(newConfig);

      dispatch(
        displayToast({
          id: uuidv4(),
          text: t(translation.widgetBuilder.saved),
          variant: ComponentType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        displayToast({
          id: uuidv4(),
          text: t(translation.widgetBuilder.savingError),
          variant: ComponentType.ERROR,
        }),
      );
      console.error(error);
    } finally {
      setIsPublishing(false);
    }
  };

  const onSaveAs = () => {
    if (!configRef.current.dashboard) return;
    const newName = prompt(t(translation.widgetBuilder.saveAsPrompt), configRef.current.dashboard?.name);
    if (!newName) return;

    const newConfig = cleanDeep(cloneDeep(configRef.current), { cleanKeys: ["id"], emptyArrays: false, emptyObjects: false, emptyStrings: false });
    if (newConfig.dashboard) {
      newConfig.dashboard.name = newName;
    }
    setWidgetConfig(newConfig);
    onPublish(newConfig);
  };

  return (
    <div className="widget-builder">
      <header>
        <div>
          <Icon icon={IconTypes.ARROW_LEFT} onClick={() => navigate("/")} size={40} />
          <DashboardSelector onDashboardSelected={onDashboardSelected} />
        </div>
        <div>
          <FilledButton onClick={() => handleOnRun()} colorVariant={Variant.WARN} disabled={!hasInitSent}>
            {t(translation.widgetBuilder.run)}
          </FilledButton>
          <div className="button-group">
            <FilledButton disabled={!isConfigValid} loading={isPublishing} onClick={() => onPublish()}>
              {t(translation.widgetBuilder[config.dashboard?.id ? "save" : "publish"])}
            </FilledButton>
            <FilledButton disabled={!isConfigValid || isPublishing} onClick={() => setAreSaveOptionsVisible(true)}>
              ▼
            </FilledButton>
            <BaseDropdown isOpened={areSaveOptionsVisible} onClose={() => setAreSaveOptionsVisible(false)}>
              <div className="save-as-dropdown-menu-item" onClick={onSaveAs}>
                {t(translation.widgetBuilder.saveAs)}
              </div>
            </BaseDropdown>
          </div>
        </div>
      </header>{" "}
      <main className="container">
        <SplitPane sizes={sizes} onChange={setSizes} sashRender={() => <></>}>
          <Pane>
            <Lightbeam show={showEditorLightbeam} onAnimationEnd={hideEditorLightbeam} />
            <div className="editor">
              <WidgetJsonEditor value={config} onChange={onChange} />
            </div>
          </Pane>
          <Pane>
            <PreviewPageSwitcher pages={configRef.current?.dashboard?.pages} onClick={setActivePageIndex} activePageIndex={activePageIndex} />
            <div className={classNames("preview", { hidden: !shouldShowPreview })}>
              {showPreviewUpdateIndicator && <div className="preview-update-indicator" onAnimationEnd={hidePreviewUpdateIndicator}></div>}
              <iframe src={widgetBuilderUrl} ref={iframeRef} onLoad={onPageLoaded} onError={console.error} />
            </div>
          </Pane>
        </SplitPane>
      </main>
    </div>
  );
};
