import React, { useCallback, useState } from "react";
import { DefaultTable, IconName, FilledButton, Variant } from "@technis/ui";
import classNames from "classnames";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";
import { useUsersSearch } from "../../utils/useUsersSearch";
import { getUserRows, getUsersTableColumns } from "../../utils/usersTableHelpers";
import { InvitationModal } from "./InivtationModal";
import { EditUserModal } from "./EditUserModal";
import { useQuery } from "@apollo/client";
import { UserByIdQueryResult, USER_BY_ID_QUERY } from "@services/userService";
import { DeleteUserModal } from "./DeleteUserModal";
import { ViewAsModal } from "@views/UsersSearch/ViewAsModal";
import { User } from "@technis/shared";
import { SearchWrapper } from "@components/searchHelpers";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE } from "@utils/utils";

export const UsersSearch = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isInvitationModalVisible, setIsInvitationModalVisible] = useState(false);
  const [isViewAsModalVisible, setIsViewAsModalVisible] = useState(false);
  const [viewAsUserId, setViewAsUserId] = useState<User["id"]>("");
  const [isBulkInvitation, setIsBulkInvitation] = useState(false);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false);
  const [userIdToUpdate, setUserIdToUpdate] = useState<string>("");
  const [userIdToDelete, setUserIdToToDelete] = useState<string>("");
  const { users, refetchUsers, fetchLoading } = useUsersSearch();
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
  const { data } = useQuery<UserByIdQueryResult>(USER_BY_ID_QUERY, { variables: { userId: userIdToUpdate }, skip: !userIdToUpdate });

  const debouncer = useCallback(
    debounce(
      (newValue: string) => {
        refetchUsers({ searchValue: newValue });
        setIsDebouncing(false);
      },
      DEFAULT_DEBOUNCE,
      { trailing: true },
    ),
    [refetchUsers],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    setIsDebouncing(true);
    debouncer(newValue);
  };

  const onClickCreate = () => {
    console.log("create");
  };

  const onClickInvite = () => {
    setIsInvitationModalVisible(true);
  };

  const onClickBulkInvite = () => {
    setIsInvitationModalVisible(true);
    setIsBulkInvitation(true);
  };

  const onCloseInvitationModal = () => {
    setIsInvitationModalVisible(false);
    setIsBulkInvitation(false);
  };

  const onClickEdit = (userId: string) => {
    setIsEditUserModalVisible(true);
    setUserIdToUpdate(userId);
  };

  const onCloseEditUserModal = () => {
    setIsEditUserModalVisible(false);
    setUserIdToUpdate("");
  };

  const onClickDelete = (userId: string) => {
    setIsDeleteUserModalVisible(true);
    setUserIdToToDelete(userId);
  };

  const onCloseDeleteUserModal = () => {
    setIsDeleteUserModalVisible(false);
    setUserIdToToDelete("");
  };

  const onSuccessDelete = () => {
    refetchUsers({ searchValue });
  };

  const onClickViewAsUser = async (userId: User["id"]) => {
    setIsViewAsModalVisible(true);
    setViewAsUserId(userId);
  };

  const onCloseViewAsUserModal = () => {
    setIsViewAsModalVisible(false);
    setViewAsUserId("");
  };

  const usersTableColumns = getUsersTableColumns(onClickEdit, onClickDelete, onClickViewAsUser);

  return (
    <SearchWrapper
      iconName={IconName.USER_GROUP}
      title={i18n.t(translation.search.users)}
      onChange={onChange}
      searchValue={searchValue}
      buttons={
        <div className="container">
          <FilledButton onClick={onClickCreate} text={i18n.t(translation.users.create)} className="user-button" colorVariant={Variant.ACCENT} />
          <FilledButton onClick={onClickInvite} text={i18n.t(translation.users.invite)} className="user-button" />
          <FilledButton onClick={onClickBulkInvite} text={i18n.t(translation.users.bulkInvite)} className={classNames("user-button", "user-button-blue")} />
        </div>
      }
    >
      {searchValue && <DefaultTable columns={usersTableColumns} rows={getUserRows(users, usersTableColumns)} className="results-table" />}
      <InvitationModal isModalVisible={isInvitationModalVisible} isBulkInvitation={isBulkInvitation} onCloseModal={onCloseInvitationModal} />
      <ViewAsModal isModalVisible={isViewAsModalVisible} onCloseModal={onCloseViewAsUserModal} userId={viewAsUserId} />
      {data && !fetchLoading && !isDebouncing && <EditUserModal user={data.userById} isModalVisible={isEditUserModalVisible} onCloseModal={onCloseEditUserModal} />}
      <DeleteUserModal userId={userIdToDelete} isModalVisible={isDeleteUserModalVisible} onCloseModal={onCloseDeleteUserModal} onSuccessDelete={onSuccessDelete} />
    </SearchWrapper>
  );
};
